import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Section } from "../../layout";
import { Image, Gallery, Thumb, TrailguidePlugin } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`So many great trails were already uploaded by our Trailguide User Community,
that we have to show you the best new spots for mountain biking already in the
middle of the season.`}</p>
    <br />
    <br />
    <p>{`More precisely, over 500 trails were added in the
first half of the season, and everyday there are more and more. We picked out
the best new spots you can discover with `}<a parentName="p" {...{
        "href": "https://www.trailguide.net"
      }}>{`trailguide.net`}</a>{`
and narrowed down the field to 16 destinations, we are going to introduce here
in two parts `}<a parentName="p" {...{
        "href": "/news/newspots2018part1"
      }}>{`(find part 1 here)`}</a>{`.`}</p>
    <br />
    <br />
    <p>{`In PART 2 you find Ligurian goods (off the trouble in Finale Ligure), the
French Côte d'Azur, riding at the foothills of Mont-Blanc, and hot
Spanish Enduro trails:`}</p>
    <Image src="news/bestofmap3.jpg" mdxType="Image" />
    <p>{`In the second part, we show you 8 destinations for great singletrack
mountainbiking, further south. So as it slowly becomes autumn, you can escape
from the cold. The Trail-Maps are interactive. You can move the map, zoom,
click on trails, unfold the trail list ...
Like you are used from `}<a parentName="p" {...{
        "href": "https://www.trailguide.net"
      }}>{`trailguide.net`}</a></p>
    <Section mdxType="Section" />
    <h2>{`1 - Sestri Levante, Liguria, Italy`}</h2>
    <h4>{`11 trails, 28km, Photo: Peter Seidl, Trail: I cani`}</h4>
    <Image src="news/bestofsestrilevante2.jpg" mdxType="Image" />
    <TrailguidePlugin content="lat=44.2849&lng=9.4313&coverage=5" mdxType="TrailguidePlugin" />
    <Gallery mdxType="Gallery">
  <Thumb src="news/bestofsestrilevante1.jpg" mdxType="Thumb">
    Sestri Levante, Liguria, Italy
  </Thumb>
  <Thumb src="news/bestofsestrilevante3.jpg" mdxType="Thumb">
    Sestri Levante, Liguria, Italy
  </Thumb>
  <Thumb src="news/bestofsestrilevante5.jpg" mdxType="Thumb">
    Sestri Levante, Liguria, Italy
  </Thumb>
  <Thumb src="news/bestofsestrilevante4.jpg" mdxType="Thumb">
    Sestri Levante, Liguria, Italy
  </Thumb>
    </Gallery>
    <Section mdxType="Section" />
    <h2>{`2 - Genova, Liguria, Italy`}</h2>
    <h4>{`23 trails, 65,5km, Photo: Dario Lioy, Trail: Eremita`}</h4>
    <Image src="news/bestofgenova.jpg" mdxType="Image" />
    <TrailguidePlugin content="lat=44.4438&lng=8.9130&coverage=10" mdxType="TrailguidePlugin" />
    <Section mdxType="Section" />
    <h2>{`3 - Dolceacqua, Liguria, Italy`}</h2>
    <h4>{`4 trails, 9km, Photo: Peter Seidl, Trail: Alpicella`}</h4>
    <Image src="news/bestofdolceaqua.jpg" mdxType="Image" />
    <TrailguidePlugin content="lat=43.8557&lng=7.6230&coverage=5" mdxType="TrailguidePlugin" />
    <Gallery mdxType="Gallery">
  <Thumb src="news/bestofdolceaqua_4.jpg" mdxType="Thumb">Dolceacqua, Liguria, Italy</Thumb>
  <Thumb src="news/bestofdolceaqua_2.jpg" mdxType="Thumb">Dolceacqua, Liguria, Italy</Thumb>
  <Thumb src="news/bestofdolceaqua_3.jpg" mdxType="Thumb">Dolceacqua, Liguria, Italy</Thumb>
  <Thumb src="news/bestofdolceaqua_1.jpg" mdxType="Thumb">Dolceacqua, Liguria, Italy</Thumb>
    </Gallery>
    <Section mdxType="Section" />
    <h2>{`4 - Sospel, Côte d'Azur, France:`}</h2>
    <h4>{`14 trails, 59km, Photo: Peter Seidl, Trail: The Ridge`}</h4>
    <Image src="news/bestofsospel.jpg" mdxType="Image" />
    <TrailguidePlugin content="lat=43.8917&lng=7.4744&coverage=3" mdxType="TrailguidePlugin" />
    <Gallery mdxType="Gallery">
  <Thumb src="news/bestofsospel_1.jpg" mdxType="Thumb">Sospel, Côte d'Azur, France</Thumb>
  <Thumb src="news/bestofsospel_2.jpg" mdxType="Thumb">Sospel, Côte d'Azur, France</Thumb>
  <Thumb src="news/bestofsospel_3.jpg" mdxType="Thumb">Sospel, Côte d'Azur, France</Thumb>
  <Thumb src="news/bestofsospel_7.jpg" mdxType="Thumb">Sospel, Côte d'Azur, France</Thumb>
  <Thumb src="news/bestofsospel_5.jpg" mdxType="Thumb">Sospel, Côte d'Azur, France</Thumb>
  <Thumb src="news/bestofsospel_6.jpg" mdxType="Thumb">Sospel, Côte d'Azur, France</Thumb>
  <Thumb src="news/bestofsospel_4.jpg" mdxType="Thumb">Sospel, Côte d'Azur, France</Thumb>
    </Gallery>
    <Section mdxType="Section" />
    <h2>{`5 - Olargues, Occitanie, France`}</h2>
    <h4>{`6 trails, 66km, Photo: Sven Martin, Trail: Crêtes XXL`}</h4>
    <Image src="news/bestofolargues.jpg" mdxType="Image" />
    <TrailguidePlugin content="lat=43.5673&lng=2.9897&coverage=5" mdxType="TrailguidePlugin" />
    <Gallery mdxType="Gallery">
  <Thumb src="news/bestofolargues2.jpg" mdxType="Thumb">Olargues, Occitanie, France</Thumb>
  <Thumb src="news/bestofolargues3.jpg" mdxType="Thumb">Olargues, Occitanie, France</Thumb>
  <Thumb src="news/bestofolargues4.jpg" mdxType="Thumb">Olargues, Occitanie, France</Thumb>
    </Gallery>
    <Section mdxType="Section" />
    <h2>{`6 - Chamonix-Mont-Blanc, Auvergne-Rhône-Alpes, France`}</h2>
    <h4>{`28 trails, 166km, Photo: Thorleif Nøkleby, Trail: Le Tour - Catogne - Trient`}</h4>
    <Image src="news/bestofchamonix1.jpg" mdxType="Image" />
    <TrailguidePlugin content="lat=45.9651&lng=6.8891&coverage=15" mdxType="TrailguidePlugin" />
    <Gallery mdxType="Gallery">
  <Thumb src="news/bestofchamonix.jpg" mdxType="Thumb">Chamonix-Mont-Blanc, France</Thumb>
  <Thumb src="news/bestofchamonix2.jpg" mdxType="Thumb">Chamonix-Mont-Blanc, France</Thumb>
  <Thumb src="news/bestofchamonix3.jpg" mdxType="Thumb">Chamonix-Mont-Blanc, France</Thumb>
    </Gallery>
    <Section mdxType="Section" />
    <h2>{`7 - Cuenca, Castilla-La Mancha, Spain`}</h2>
    <h4>{`6 trails, 12km, Photo: Yaroslav Alpizar Zhuravlev, Trail: Tiradores`}</h4>
    <Image src="news/bestofcuenca2.jpg" mdxType="Image" />
    <TrailguidePlugin content="lat=40.0832&lng=-2.1158" mdxType="TrailguidePlugin" />
    <Gallery mdxType="Gallery">
  <Thumb src="news/bestofcuenca1.jpg" mdxType="Thumb">Cuenca, Spain</Thumb>
  <Thumb src="news/bestofcuenca3.jpg" mdxType="Thumb">Cuenca, Spain</Thumb>
  <Thumb src="news/bestofcuenca4.jpg" mdxType="Thumb">Cuenca, Spain</Thumb>
  <Thumb src="news/bestofcuenca5.jpg" mdxType="Thumb">Cuenca, Spain</Thumb>
    </Gallery>
    <Section mdxType="Section" />
    <h2>{`8 - Olba, Aragón, Spain`}</h2>
    <h4>{`12 trails, 31km, Photo: Vicent Reig Mira, Trail: Penarroya`}</h4>
    <Image src="news/bestofenduroland_1.jpg" mdxType="Image" />
    <TrailguidePlugin content="lat=40.1362&lng=-0.6050" mdxType="TrailguidePlugin" />
    <Gallery mdxType="Gallery">
  <Thumb src="news/bestofenduroland_2.jpg" mdxType="Thumb">Olba, Spain</Thumb>
  <Thumb src="news/bestofenduroland_3.jpg" mdxType="Thumb">Olba, Spain</Thumb>
  <Thumb src="news/bestofenduroland_4.jpg" mdxType="Thumb">Olba, Spain</Thumb>
  <Thumb src="news/bestofenduroland_5.jpg" mdxType="Thumb">Olba, Spain</Thumb>
    </Gallery>
    <Section mdxType="Section" />
    <h2>{`Upload your tracks!`}</h2>
    <Image src="news/uploadtrails_5.jpg" mdxType="Image" />
    <p>{`Trailguide is an open platform and you can also add your trails! Almost every
GPS device or app can record the data as a .gpx file. Open
`}<a parentName="p" {...{
        "href": "https://www.trailguide.net"
      }}>{`trailguide.net`}</a>{` on your computer and drag and
drop the file into the 'Add trail' window. If needed, crop your loop or ride
to cover only the singletrack parts using the slider below the map. Add
additional info and thats it! You uploaded your track :-)`}</p>
    <Section mdxType="Section" />
    <h2>{`Find new trails`}</h2>
    <Image src="news/sortbylatestaddition.jpg" mdxType="Image" />
    <p>{`On `}<a parentName="p" {...{
        "href": "https://www.trailguide.net"
      }}>{`trailguide.net`}</a>{` you can easily browse the
recently added trails. Opening the app, you see an overview over Europe.
Then select -Sort by latest addition- to see whats new. On the phone it works
the same way. Click on -Trails- to open the list and there you can change the
order.`}</p>
    <br />
    <br />
    <p>{`It always applies to the area the map shows, so when you zoom into a region,
you see the latest added trails there`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      